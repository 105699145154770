@font-face {
    font-family: 'crimsontext';
    src: url('fonts/crimson_text/CrimsonText-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'crimsontext';
    src: url('fonts/crimson_text/CrimsonText-Bold.ttf') format('truetype');
    font-weight: bold;
}


/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
 */

* {
    box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}


body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    background: #cecece;
    padding: 0 .25em;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-size: .75em;
}

button {
    cursor: pointer;
}

/**/
/*START NEW STUFF*/
/**/

body {
    font-family: 'crimsontext', 'helvetica', 'sans-serif';
}

.strong {
    font-weight: 700;
}
.italic {
    font-style: italic;
}

header.main-header {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    z-index: 100;
}

.header-grid {
    grid-column: 1;
    grid-row: 1 / span 2;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
}

.header-grid .logo-container {
    grid-column: 1 / span 2;
    grid-row: 1 / span 3;
}

.header-grid .logo-container img {
    width: 100%;
    padding: 1em;
}

.header-grid .nav-links {
    grid-column: 3 / span 5;
    background: #fff;
    grid-row: 2;
    border: 1px solid #000;
    text-align: center;
}
.header-grid .nav-links ul {
    overflow: hidden;
}
.header-grid .nav-links ul li {
    display: inline-block;
}
.header-grid .nav-links ul li:last-child {
    margin: 0;
}

.nav-links a {
    color: #000;
    text-decoration: none;
    display: inline-block;
    padding: .5em .5em;
}
.nav-links a.active {
    box-shadow: 0 0 12px #000;
}


.header-underlay {
    grid-column: 1;
    grid-row: 1;
    background: #000;
    z-index: -1;
}

.beartoons {
    margin-top: -2em;
}

.beartoons .stage {
    position: relative;
}

.beartoons .series-info {
    display: grid;
    grid-template-columns: 3fr 10px 1fr;
    padding: 0 20px;
}
.beartoons .series-info .sequence {
    text-align: right;
}

.beartoons .hero, .beartoons .captions, .beartoons .controls {
    width: 100%;
    text-align: center;
}

.beartoons .hero {
    height: 73%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
}

.beartoons .hero .image-container {
    width: 100%;
    padding: 20px 20px 10px;
}

.beartoons .captions {
    font-family: 'times new roman';
    font-style: italic;
    font-size: 1.5em;
    padding: 0 1em 1em;
}

.beartoons .controls {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: .5em;
}
.beartoons .controls .nav-button button {
    padding: .3em .5em .15em;
    border-radius: .45em;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: underline;
    font-family: 'crimsontext', sans-serif;
    font-size: 1em;
    font-weight: 700;
}
.beartoons .controls .nav-button button:hover, .beartoons .controls .nav-button button:active {
    background: rgba(0, 0, 0, .15);
}
.nav-button button span {
    vertical-align: text-bottom;
}

.beartoons .controls .nav-button.disabled {
    display: none;
}

.beartoons .controls .left {
    grid-column: 3;
    text-align: left;
}
.beartoons .controls .center {
}
.beartoons .controls .right {
    grid-column: 4;
    text-align: right;
}

.copy-page {
    margin-top: -2em;
    padding: 2em 1em;
}
.copy-page h1 {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 1em;
    padding-bottom: .2em;
    border-bottom: 1px solid #000;
}
.copy-page h2 {
    font-weight: 700;
    margin-bottom: 1em;
}
.copy-page p {
    margin-bottom: 1em;
    line-height: 1.5em;
}
.copy-page ul.contact-methods {
    margin-bottom: 2em;
}
.copy-page ul.contact-methods li {
    display: inline-block;
    margin: 0 1em;
}
.copy-page ul.list {
    list-style-type: disc;
    margin-bottom: 1em;
}
.copy-page ul.list li {
    margin-bottom: .25em;
    margin-left: 2em;
}

.comic-cell {
    border: 2px solid #000;
    box-shadow: 6px 6px 0 #000;
    margin-bottom: 2em;
}

.grid {
    display: grid;
}

.apps-page .app-listing-container {
    padding: 1em;
}

.apps-page .app-listing-container .side-note {
    color: #999;
    font-size: .9em;
    margin-left: 1em;
}

.beartoon {
    margin: 1em auto 2em;
    width: 90%;
}

@media only screen and (max-width: 1023px) {
}

@media only screen and (min-width: 1024px) {
    body {
        font-size: 24px;
    }
    .header-grid {
        width: 840px;
        margin: 0 auto;
    }
    
    .grid-2col {
        grid-template-columns: repeat(2, 1fr);
    }
    .grid-2col > div {
        margin-right: 1em;
        padding-right: 1em;
        border-right: 1px solid #cecece;
    }
    .grid-2col > div:last-child {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
    }

    .copy-page, .beartoons {
        width: 840px;
    }
    .copy-page {
        margin: -2em auto 2em;
    }
    .beartoons {
        margin: -3em auto;
    }
}
